@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Inter, sans-serif;
    font-size: 14px;
  }

}

li {
  font-size: 14px;
  line-height: 24px;
}

.nm_about {
  font-family: 'IBM Plex Mono', monospace;
  font-weight: 600;
}

.tooltip.react-tooltip {
  background-color: #fff;
  color: black;
  border: 1px solid black;
  font-family: 'IBM Plex Mono', monospace;
  font-size: 10px;
  opacity: 0.5;
}

.tooltip.react-tooltip__place-top>.react-tooltip-arrow {
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}

.tooltip.react-tooltip__place-right>.react-tooltip-arrow {
  border-bottom: 1px solid black;
  border-left: 1px solid black;
}

.tooltip.react-tooltip__place-bottom>.react-tooltip-arrow {
  border-top: 1px solid black;
  border-left: 1px solid black;
}

.tooltip.react-tooltip__place-left>.react-tooltip-arrow {
  border-top: 1px solid black;
  border-right: 1px solid black;
}

@media screen and (max-width: 1280px) {
  .dropdown-body {
    width: 148px !important;
  }
}

@media screen and (max-width: 767px) {
  .home-hero {
    height: 350px !important;
  }
}